.mando{
  background-color: #0f0f0f !important;
  height: 98vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-image: url("./assets/Images/metal.webp");
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
}

*{
  font-family: Azonix;
}

:root{

}

.scrollToTop i{
  position: fixed;
  bottom: 10px;
  right: 1.5vw;
  color: #9147fe;
  background-color: #000000;
  border-radius: 20px;
  z-index: 4;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
    background-color: #0f0f0f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Arkship';
  src: local('Arkship'), url(./fonts/Arkhip/Commercial/TTF/Arkhip-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nordic';
  src: local('Nordic'), url(./fonts/Nordic/Nordic\ Alternative\ Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Anurati';
  src: local('Anurati'), url(./fonts/anurati_font/Anurati-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Azonix';
  src: local('Azonix'), url(./fonts/Azonix.otf) format('truetype');
}

@font-face {
  font-family: 'Potra';
  src: local('Potra'), url(./fonts/Potra.otf) format('truetype');
}

h1, h2{
  font-family: Azonix;
}

p{
  font-family: Arkship;
}

.header{
  position: relative;
}

.header__info{
  position: absolute;
}

.header__title h1{
  margin-left: 80px;
  color: whitesmoke;
  font-family: Azonix;
}

.header__title p{
  margin-top: -10px;
  margin-left: 90px;
  max-width: 480px;
  padding-left: 20px;
  padding-right: 20px;
  color: whitesmoke;
  font-family: Azonix;
}

mark{
  background-color: #9147fe;
}

.arrow {
  text-align: center;
  z-index: 5;
  display: inline;
  position: absolute;
  top: 90vh;
  right: 8vw;
  height: 100px;
}

.arrow i{
  position: absolute;
  top: 50px;
  color:#9147fe;
  z-index: 5;
}

.arrow p{
  position: absolute;
  top: -50px;
  right: 10px;
  /* transform: rotate(90deg); */
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


nav{
position: absolute;
z-index: 1;
right: 90px;
font-family: Azonix;
}


nav ul li {
  display: inline;
}

nav ul li a{
  margin-left: 10px;
  color: whitesmoke;
  text-decoration: none;
}


.nav__dropdown{
  visibility: hidden;
  height: 100px!important;
  z-index: 3;
}

.nav__dropdown ul{
  background-color: #9147fe;
  list-style-type: none;
  border-radius: 10px;
  z-index: 3
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #9147fe;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media only screen and (max-width: 1080px) {
  .main__nav {
    background-color: lightblue;
    visibility: hidden;
  }
  .nav__dropdown{
    visibility: visible;
    margin-right: -80px;
    margin-top: -20px;
  }
}

@media only screen and (max-width:750px){
  .nav__dropdown{
    margin-right: -130px;
    margin-top: 50px;
  }
}

/* Dropdown Button */

.dropbtn {
  background-image: url("./assets/Images/menu.webp");
  background-size: cover;
  border: rgb(153, 153, 153) 2px solid !important;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownRel{
  position: absolute;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  right: -10px;
  text-align: center;
  min-width: 100px;
  width: 70vw;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: rgb(216, 214, 214);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 40px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  text-decoration: underline 3px solid black;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

.home{
  width: 100%;
}

.home__about{
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

span{
  color: #9147fe;
  padding-left: 10px;
  font-size: x-large;
}

.home__featured__projects__content{
  background-size: cover;
  padding-top: 20px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

/* Work Page */
.projects{
  width: 80%;
  margin: 0 auto;
}


.projects__content h2{
  padding: 40px;
text-align: center;
}

/* About */

.about__content{
width: 80%;
margin: 40px auto;
}

.about__text h1{
  background-color: #9147fe;
  text-align: center;
  width: 210px;
  height: 40px;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 10px;
  font-size: 2rem;
}

.about__text span{
  color: black;
  padding: 4px;
}

.about__text p{
  text-indent: 30px;
}

.about__skills{
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.skills{
  width: 100%;
}

.skills ul{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}

.design__skills{
  margin-top: 40px;
}

.interested__topics{
  margin-top: 100px !important;
  width: 80%;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
}

.interested__topics__content{
width: 100%;
margin: 0 auto;
}

.interested__topics__content h2{
  padding: 20px;
  font-size: large
}

.interested__topics__content p{
  margin-top: -30px;
}

/* Contact */

.contact__info{
  width: 90%;
  padding-top: 20px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

}

.contact__info p{
  text-align: center;
  margin: 0 auto;
  width: 75%;  
}

.contact__buttons{
  margin-top: 15px;
  width: 100vw;
}

.contact__buttons button{
  width: 120px;
  height: 30px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.container{
  width: 100vw;
}

.form{
  display: block;
  width: 80%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.form-group{
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-control {
  width: 90%;
  padding: 10px;
  resize: none;
}

.button__left-right {
  margin-top: 10px;
  border: 2px solid #9147fe;
  background: linear-gradient(to right, #9147fe 50%, #252525 50%);
  background-size: 200% 100%;
  background-position: right;
  transition: all .35s ease-out;
  color: white;
}

.button__left-right:hover {
  border: 2px solid rgb(255, 255, 255);
  background-position: left;
  color: rgb(0, 0, 0);
}

/* Home Featured */

.home__featured__projects{
  width: 90vw;
}

.home__featured__projects__content h2{
  text-align: center;
  color: #000000;
  font-weight: 600;
}

.home__featured__projects__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #3a3a3b;
}

.featured__project__card{
  margin: 10px auto;
  background-color: #252525;
  width: 90%;
  max-width: 480px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.featured__project__card:hover{
  transform: translateY(-5px) translateX(-5px);
  box-shadow: 0.5rem 0.5rem hsl(264, 99%, 64%, 90%);
}

.project__card__content h3, .project__card__content p, .project__card__content a{
  text-align: center;
  color: white;
  text-decoration: none;
  padding: 10px;
}

.project__card__content h3:hover{
  color: #9147fe;
}

.project__card__text h3{
  margin-top:20px;
}

.project__card__text p{
margin-bottom: 40px !important;
}

/* Work */

.projects__content{
  width: 100%;
}

.project__card{
  position: relative;
  background-color: #252525;
  max-width: 1400px;
  padding: 20px 0;
  margin: 20px auto;
  border-radius: 10px;
  z-index: 1;
}

#imageMock1{
  max-width: 600px;
  margin: 0 auto;
}
#imageMock2{
  max-width: 120px;
  position: absolute;
  top: 100px;
  right: 20%;
  visibility: hidden;
}
#imageMock3{
  max-width: 250px;
  position: absolute;
  top: 30px;
  left: 10%;
  visibility: hidden;
}

.project__text{
  text-align: center;
  height: 180px;
  margin: 0 auto;
  padding: 20px;
}

.project__text p{
  margin: 2px;
  padding-bottom: 10px;
}

.project__text h3{
  color: #ffffff;
  background-color: #9147fe;
  width:250px;
  max-width: 600;
  height: 20px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 30px;
}

.project__text a{
  padding: 10px;
  text-decoration: none;
}

.project__content{
padding: 10px;
}

.project__content img{
  display: block;
  width: 100%;
  max-width: 480px;
  border-radius: 5px;
}

#project__card__container{
  height: 200px;
}

.project__card__content img{
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.project__card_text{
  padding: 20px;
  text-decoration: none;
}

.all__works__btn{
  width: 120px;
  margin: 20px auto;
  text-decoration: none;
  font-family: Azonix;
  text-align: center;
}

.button__top-bottom {
  border: 2px solid #9147fe;
  background: linear-gradient(to bottom, #9147fe 50%, #252525 50%);
  background-size: 100% 200%;
  background-position: bottom;
  transition: all .35s ease-out;
  color: white;
}

.button__top-bottom:hover {
  border: 2px solid rgb(255, 255, 255);
  background-position: top;
  color: rgb(0, 0, 0);
}

.work__link{
  color: white;
  text-decoration: none;
  padding-l: 10px;
}

.work__btn{
  margin-left: 10px;
  margin-right: 10px;
  width: 120px;
}

.work__link:hover{
  color: black;
}

@media all and (max-width:600px){
  .mando{
    pointer-events: none;
  }
  .header__title{
    width: 100% !important;
    margin: 0 -60px;
    padding: 20px;
  }
  .dropdown-content{
    right: 0px !important;
  }
  .dropbtn{
    margin-top: 30px;
    width: 50px !important;
    height: 50px !important;
  }
}

@media all and (min-width: 300px){
  .arrow{
    visibility: hidden;
  }
  .section__title{
    font-size: 1.8rem !important;
  }
  .header{
    margin-top: 30px;
  }
  h1,h2,h3{
    font-size: .8rem;
  }
  .about__text h1{
    font-size: .8rem;
    height: 30px;
    padding-top: 5px;
  }
  p{
    font-size: .7rem;
  }
  .row{
    font-size: 0.68rem;
    padding-left: 0px;
    padding-right: 20px;
  }
  .header__info h1{
    font-size: 1.7rem;
    text-align: center;
  }
  .scrollToTop i{
    right: 40px;
  }
  .home__featured__projects__content{
    grid-template-columns: repeat(1,1fr);
    column-gap: 4px;
  }
  .project__card{
    font-size: 0.8rem;
  }
  .about__text h1{
    text-align: center;
  }
  .project__card__text h3{
    font-size: .9rem;
  }
  .project__card__text p{
    font-size: .8rem;
  }
}

@media all and (min-width: 450px) {
  .arrow{
    visibility: visible;
  }
  h2 {
    font-size: 2.5rem;
  }
  .row{
    width: 90%;
  }
  .header{
    margin-top: 0px;
  }
  .dropdown-content{
    right: 0px !important;
  }
  .dropbtn{
    margin-right: -10px;
  }
}

@media all and (min-width: 1040px) {
  .home__about p{
    padding: 0 15%;
  }

  h1,h2,h3{
    font-size: 1.1rem;
  }
  #about-bubble{
    font-size: 2rem;
    height: 35px;
    padding-top: 10;
  }
  p{
    font-size: .9rem;
  }
  .home__featured__projects__content{
    grid-template-columns: repeat(1,1fr);
    column-gap: 4px;
  }
  .row{
    width: 90%;
  }
  nav{
    right: 90px;
  }
  .project__card{
    font-size: 0.9rem;
  }
  .project__text h3{
    width: 400px;
  }
  .dropdown-content{
    right: -10px !important;
  }
}

@media all and (min-width: 1080px) {
  .home__featured__projects__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4px;
  }

  h2 {
    grid-column: 1 / 4;
  }

  .all__works__btn {
    grid-column: 1 / 4;
  }
  nav{
    right: 110px;
  }

}

@media all and (min-width: 1500px) {
  #imageMock2, #imageMock3{
    visibility: visible;
  }
}
/* Footer */

.footer__container{
  width: 100%;
}


.footer__container p {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footer__container a{
  padding: 20px;
  color: #9147fe;
}

.footer__container a:hover{
  color: rgb(255, 255, 255); 
  transition: all 0.5s ease-in-out;
}

.footer__container h1{
  text-align: center;
  font-size: 0.6rem;
  font-weight: 300;
}